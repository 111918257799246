<template>
  <v-row>
    <v-col>
      <v-card class="p-4">
        <v-card-title>
          <h1>{{ pageTitle }}</h1>
        </v-card-title>
        <v-card-text>          
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="3">
                  <input-v
                    v-model="dados.plataforma"
                    :label="fields.plataforma"
                    :valid="validation.get(fields.plataforma)"
                    @update:valid="updateValidation(fields.plataforma, $event)"
                  ></input-v>
                </v-col>
                <v-col cols="2">
                  <input-v
                    v-model="dados.ano"
                    :label="fields.ano"
                    :opts="opts"
                    :valid="validation.get(fields.ano)"
                    @update:valid="updateValidation(fields.ano, $event)"
                  ></input-v>
                </v-col>
                <v-col cols="4">
                  <input-v
                    v-model="dados.url"
                    :label="fields.url"
                    :valid="validation.get(fields.url)"
                    @update:valid="updateValidation(fields.url, $event)"
                    :rules="validationUrl(dados.url, fields.url)"                    
                  ></input-v>
                  <span style="color: #ca0d0d ; font-style: italic; margin-top: -3px; line-height: 12px;" v-if="isVisibleUrlError">O campo URL está inválido, preencha o http:// ou https://</span>
                </v-col>
                <v-col cols="3">
                  <input-v
                    v-model="dados.ativo"
                    :label="fields.ativo"
                    :valid="validation.get(fields.ativo)"
                    @update:valid="updateValidation(fields.ativo, $event)"
                    :opts="opts"
                  ></input-v>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="8">
                  <input-v
                    v-model="dados.representante"
                    :label="fields.representante"
                    :valid="validation.get(fields.representante)"
                    @update:valid="updateValidation(fields.representante, $event)"
                  ></input-v>
                </v-col>
                <v-col cols="4">
                  <input-v
                    v-model="dados.cpf"
                    :label="fields.cpf"
                    :valid="validation.get(fields.cpf)"
                    @update:valid="updateValidation(fields.cpf, $event)"
                  ></input-v>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="8">
                  <input-v
                    v-model="dados.empresa"
                    :label="fields.empresa"
                    :valid="validation.get(fields.empresa)"
                    @update:valid="updateValidation(fields.empresa, $event)"
                    :opts="opts"
                    @input="selectEmpresa($event)"
                  ></input-v>
                </v-col>
                <v-col cols="4">
                  <input-v
                    v-model="dados.cnpj"
                    :label="fields.cnpj"
                    :valid="validation.get(fields.cnpj)"
                    @update:valid="updateValidation(fields.cnpj, $event)"
                  ></input-v>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <input-v
                    v-model="dados.usuario"
                    :label="fields.usuario"
                    :valid="validation.get(fields.usuario)"
                    @update:valid="updateValidation(fields.usuario, $event)"
                  ></input-v>
                </v-col>
                <v-col cols="4">
                  <input-v
                    v-model="dados.senha"
                    :label="fields.senha"
                    :valid="validation.get(fields.senha)"
                    @update:valid="updateValidation(fields.senha, $event)"
                  ></input-v>
                </v-col>
                <v-col cols="4">
                  <input-v
                    v-model="dados.beneficio"
                    :label="fields.beneficio"
                    :valid="validation.get(fields.beneficio)"
                    @update:valid="updateValidation(fields.beneficio, $event)"
                    :opts="opts"
                  ></input-v>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <input-v
                    v-model="dados.observacao"
                    :label="fields.observacao"
                    :valid="validation.get(fields.observacao)"
                    @update:valid="updateValidation(fields.observacao, $event)"
                  ></input-v>
                </v-col>
              </v-row>
              <v-card class="col p-3 col-12" outlined>
                <v-card-title class="justify-content-between">
                  <span>Campos adicionais</span>
                  <div class="d-flex justify-end">
                      <v-btn
                        dense
                        depressed
                        dark
                        color="primary"
                        @click="adicionarMaisCampos()"
                      >+ Adicionar mais</v-btn
                    >
                  </div>
                </v-card-title>
                <v-card-text>
                  <template v-for="(campo, index) in dados.camposDinamicos">
                    <v-col :key="index">
                      <v-row style="display: flex; align-items: end;">
                        <template v-for="(item, i) in itemFields">                        
                          <input-v
                            class="pa-2"
                            :key="`${index}-${i}`"
                            v-model="campo[item.key]"
                            :label="item"
                            :colSize="item.colSize"
                            @input="updateValue(campo, item.key, $event)"
                          ></input-v>
                        </template>
                        <div class="col col-1">
                          <v-btn
                              icon
                              @click="removerCampo(campo.id)"
                            >
                            <v-icon style="color: red">mdi-trash-can</v-icon>
                          </v-btn>
                          <div class="mt-5 v-text-field__details"></div>
                        </div>
                      </v-row>
                    </v-col>
                  </template>
                  <span v-if="dados.camposDinamicos.length === 0">
                    Nenhum campo adicional foi cadastrado. Clique em <b>Adicionar mais</b> para cadastrar novos campos
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end mb-3">
          <v-btn dense depressed dark color="primary" @click="enviar()">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { BeneficiosEnum, BeneficiosLabels } from "@/core/enums/beneficios";
import yearsOptions from "@/helpers/yearsOptions";
import { mapGetters } from "vuex";
import { plataformaEnum } from '@/templates/cadastro/credenciais-plataforma.enum';

export default {
  name: "CredenciaisFormComponent",
  components: {
    "input-v": () => import("@/components/input-v.vue"),
  },
  created() {
    let year = new Date().getFullYear();
    this.opts.anos = [{ value: year }, { value: year - 1 }];
    this.pageTitle = this.$route.meta.pageTitle;     
    this.doLoadEmpresas();   
    if (this.$route.meta.mode == "create") return;
    this.credencialId = this.$route.params.id;
    this.getCredencial();
  },
  methods: {
    enviar() {
      const invalidFields = this.checkFormValidation();
      if (!invalidFields) {
        this.dados.empresa_id =
          this.dados.empresa != null ? this.dados.empresa : this.$store.getters.clientId;

        const empresaId = this.$store.getters.clientId != 0 ? this.$store.getters.clientId : this.dados.empresa_id;

        const resource = this.isEditMode()
          ? this.apiResource(`/v1/rpa/${empresaId}/${this.credencialId}`)
          : this.apiResource(`/v1/rpa/${empresaId}`);

        this.dados.campos_adicionais = {};
        this.dados.camposDinamicos.map((ret) => {
          if(ret.nome.length && ret.valor.length) {
            this.dados.campos_adicionais[ret.nome] = ret.valor;
          } else {
            this.dados.camposDinamicos.splice(this.dados.camposDinamicos.indexOf(ret, 0), 1);
          }        
        })

        resource.save(this.dados).then((result) => {
          if (!result.error) {
            this.$notify({
              group: "geral",
              duration: 5000,
              type: "success",
              title: "Alterações salvas",
              text: "As alterações foram salvas com sucesso",
            });
          }
          this.$router.push({ name: 'edicao-credenciais', params: { id: result.id }})
        });
      } else {                
        const invalidFieldsName = invalidFields.map((field) => (field.name));        
        let errorTextMessage = `Preencha todos os campos obrigatórios. Os campos: ${invalidFieldsName} são de preenchimento obrigatório.`;        
        this.$notify({
          group: "geral",
          duration: 5000,
          type: "error",
          title: "Erro",
          text: errorTextMessage,
        });
      }
    },
    checkFormValidation: function () {
      this.invalidFields = [];
      this.validation.forEach((validFn, key) => {
        if (!validFn()) {
          this.invalidFields.push(key);
        }
      });
      return this.invalidFields;
    },
    updateValidation(key, value) {
      this.validation.set(key, value)
    },
    selectEmpresa(empresa) {      
      if (!empresa) return;
      const empresaSelecionada = this.listaEmpresas.filter(
        (item) => item.id == empresa.id
      )[0];
      if(empresaSelecionada) {
        this.dados.empresa = empresaSelecionada.id;
        this.dados.cnpj = empresaSelecionada.cnpj;
      }
    },
    isEditMode() {
      return this.$route.meta.mode == "update";
    },
    adicionarMaisCampos() {
      const newField = {
        id: this.dados.camposDinamicos.length,
        nome: "",
        valor: "",
      };
      this.dados.camposDinamicos.push(newField);
    },
    removerCampo(index) {
      const item = this.dados.camposDinamicos.filter((sub) => sub.id == index)[0];
      this.dados.camposDinamicos.splice(this.dados.camposDinamicos.indexOf(item, 0), 1);
    },
    updateValue(campo, key, text) {
      if (key == "nome") {
        text = this.removerCaracteresEspeciais(text);
      }
      campo[key] = text;
    },
    removerCaracteresEspeciais(texto) {
      texto = texto.replace(/[\u0300-\u036f]/g, "");
      texto = texto.replace(/[^\w]/g, "");
      return texto;
    },
    doLoadEmpresas: function () {
      return this.resourceEmpresas.get().then((response) => {
        this.opts.empresas = response.sort((a, b) =>
          a.razao_social.localeCompare(b.razao_social)
        );
        this.listaEmpresas = response;
        this.selectEmpresa(this.$store.getters.selectedClient)
      });
    },
    getCredencial() {
      const resource = this.apiResource(`/v1/rpa/${this.clientId}/${this.credencialId}`);
      return resource.get().then((result) => {
        for (const [chave, valor] of Object.entries(result.campos_adicionais)) {
          this.camposDinamicos.push({
            id: this.camposDinamicos.length,
            nome: chave,
            valor: valor,
          });
        }
        this.dados = {
          ...result,
          camposDinamicos: this.camposDinamicos,
        };
      });
    },
    validationUrl(value, field) {
      if(value && value.length > 0) {
        if (value.includes("http://") || value.includes("https://")) {        
          field.valid = true;
          return this.isVisibleUrlError = false;
        }
        field.valid = false;
        return this.isVisibleUrlError = true;
      }
    },
    isValidForm() {
      
    },
  },
  computed: {
    ...mapGetters(["clientId", "selectedClient"]),
    fields: function () {
      var fields = {};
      this.cols.forEach((col) => {
        fields[col.key] = col;
      });
      return fields;
    },
    resourceEmpresas: function () {
      return this.apiResource(`v1/clientes`);
    },
    itemFields() {
      return [
        {
          key: "nome",
          name: "Nome do Campo",
          ajuda: `Esse campo receberá a chave de referência para utilização de credenciais adicionais caso necessário por plataforma governamental. Portanto, não aceita alguns caracteres especiais (espaços, traços, acentos, etc..), se colocado será removido.`,
          type: this.$fieldTypes.TEXT,
          colSize: 5,
          rules: [{ rule: "required" }],
        },
        {
          key: "valor",
          name: "Valor do Campo",
          ajuda: `Esse campo receberá o valor de chave de referência para utilização de credenciais adicionais caso necessário por plataforma governamental`,
          type: this.$fieldTypes.TEXT,
          colSize: 6,
          rules: [{ rule: "required" }],
        },
      ];
    }
  },
  data() {
    return {
      isVisibleUrlError: false,
      pageTitle: "",
      plataformaEnum: plataformaEnum,
      cols: [
        {
          key: "id",
          name: "Código",
          type: this.$fieldTypes.NUMBER,
          hideInTable: true,
        },
        {
          key: "plataforma",
          name: "Plataforma",
          type: this.$fieldTypes.COMBOBOX,
          rel: { to: plataformaEnum },
          rules: [{ rule: "required" }],
        },
        {
          key: "ano",
          name: "Ano",
          type: this.$fieldTypes.SELECT,
          defaultValue: new Date().getFullYear(),
          rules: [{ rule: "required" }],
          rel: { to: yearsOptions, key: "value", name: "text" },
        },
        {
          key: "representante",
          name: "Representante",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }],
        },
        {
          key: "url",
          name: "URL",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }],
        },
        {
          key: "usuario",
          name: "Usuário",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }],
        },
        {
          key: "senha",
          name: "Senha",
          type: this.$fieldTypes.PASSWORD,
          rules: [{ rule: "required" }],
        },
        {
          key: "beneficio",
          name: "Benefício",
          type: this.$fieldTypes.SELECT,
          rel: { to: "beneficios", id: "id", name: "nome" },
          rules: [{ rule: "required" }],
        },
        {
          key: "observacao",
          name: "Observação",
          type: this.$fieldTypes.TEXTAREA,
        },
        {
          key: "cpf",
          name: "CPF",
          type: this.$fieldTypes.CPF,
          hideInTable: true,
        },
        {
          key: "ativo",
          name: "Ativo",
          type: this.$fieldTypes.SELECT,
          hideInTable: true,
          rel: { to: "ativos", id: "value", name: "label" },
          rules: [{ rule: "required" }],
        },
        {
          key: "empresa",
          name: "Empresa",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "empresas", key: "id", name: "razao_social" },
          rules: [{ rule: "required" }],
          editable: !this.clientId,
        },
        {
          key: "cnpj",
          name: "CNPJ",
          type: this.$fieldTypes.CNPJ,
          rules: [{ rule: "required" }],
          editable: !this.clientId,
        },
      ],
      opts: {
        ativos: [
          {
            label: "Sim",
            value: true,
          },
          {
            label: "Não",
            value: false,
          },
        ],
        beneficios: [
          {
            id: BeneficiosEnum.LEI_DO_BEM,
            nome: BeneficiosLabels.LEI_DO_BEM,
          },
          {
            id: BeneficiosEnum.LEI_DE_INFORMATICA,
            nome: BeneficiosLabels.LEI_DE_INFORMATICA,
          },
          {
            id: BeneficiosEnum.LEI_DO_BEM_E_INFORMATICA,
            nome: BeneficiosLabels.LEI_DO_BEM_E_INFORMATICA,
          },
        ],
        empresas: [],
        anos: [],
      },
      camposDinamicos: [],
      dados: {
        empresa: null,
        camposDinamicos: [],
      },
      credencialId: 0,
      listaEmpresas: [],
      validation: new Map(),
    };
  },
  watch: {
    selectedClient: function(value) {
      this.selectEmpresa(value);
    },
  },
};
</script>

<style scoped>
  .v-card__text::v-deep  {
    .col {
      padding-top: 0rem;
      padding-bottom: 0rem;
    }
  }
</style>
